<template>
  <div class="UserInfo">
    <Spacer num="1" />
    <UserAvatar :user="user" size="120px" mauto />
    <Spacer num="1" />
    <p strong mauto tcenter noprevent>{{ user.name }}</p>
    <Spacer num="2" />
    <LargeButton icon="calendars" :label="$locale['date_review']" :rightDesc="`<small>${date_locale(post.dateReview)}</small>`" prevent />
  </div>
</template>

<script>
export default {
  props: ["post"],
  computed: {
    user: function() {
      return this.post.lastUserReview;
    },
  },
};
</script>
